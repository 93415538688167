import { Logo, Spacer } from '@hapstack/ui'
import { Outlet } from '@remix-run/react'

import { PancakeBackground } from '~/components/PancakeBackground'

export default function AuthLayoutRoute() {
  return (
    <div className="flex bg-white">
      <PancakeBackground className="absolute h-full w-full" />
      <div className="grid w-full grid-cols-12">
        <section className="relative z-10 col-span-12 h-screen overflow-y-scroll bg-white px-12 py-6 sm:col-span-7">
          <div className="mx-auto max-w-lg">
            <div className="py-6">
              <Logo />
            </div>
            <Spacer size="xl" />
            <Outlet />
          </div>
        </section>
      </div>
    </div>
  )
}
